.react-datepicker {
  font-family: "Noto Sans", sans-serif;
  font-weight: 700;
  font-size: 0.8rem;
  background-color: var(--color-paper);
  color: #000;
  border: none;
  border-radius: 0;
  display: inline-block;
  position: relative;
}

.react-datepicker__day--outside-month.react-datepicker__day--in-range {
  color: white !important;
}

.react-datepicker__navigation--previous {
  border-right-color: var(--color-primary);
}

.react-datepicker__navigation--next {
  border-left-color: var(--color-primary);
}

.react-datepicker__month-container {
}

.react-datepicker__header {
  background-color: var(--color-paper);
  border-bottom: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.react-datepicker__current-month {
}

.react-datepicker__day-names {
  margin-top: 0.5rem;
}

.react-datepicker__day-name {
  width: 2rem;
  line-height: 2rem;
  text-transform: uppercase;
  color: var(--color-primary);
}

.react-datepicker__month {
}

.react-datepicker__week {
}

.react-datepicker__day {
  background-color: var(--color-calendar-unselected);
  width: 2rem;
  line-height: 2rem;
}

.react-datepicker__day--outside-month {
  background-color: transparent;
  color: var(--color-calendar-outside-month);
}

.react-datepicker__day,
.react-datepicker__day:hover {
  border-radius: 0px;
  font-family: "Noto Sans", sans-serif;
}
.react-datepicker__day--in-selecting-range {
  background-color: var(--color-calendar-in-selecting-range);
}
.react-datepicker__day--in-range {
  background-color: var(--color-calendar-in-range);
}

.react-datepicker__day
  .react-datepicker__day--029
  .react-datepicker__day--weekend
  .react-datepicker__day--outside-month {
}

.react-datepicker__day .react-datepicker__day--001 {
}

.react-datepicker__day
  .react-datepicker__day--025
  .react-datepicker__day--selected {
}
